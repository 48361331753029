<template>
  <v-app>
    <v-container fluid>
      <v-row>
        <v-col sm="12" cols="12">
          <div
            style="
              background-color: #fff;
              padding: 20px 20px;
              border-radius: 10px;
            "
          >
            <v-col sm="12" cols="12">
              <v-row>
                <v-col sm="12" cols="12" class="py-0">
                  <h2 class="mb-0" style="font-size: 25px; font-weight: 550">
                    {{ $t("welcome to annual score ") }}
                  </h2>
                  <v-snackbar
                    :color="snackbar.color"
                    v-model="snackbar.show"
                    right
                  >
                    {{ snackbar.message }}
                  </v-snackbar>
                </v-col>
              </v-row>

              <div style="margin-top: 20px"></div>

              <v-row>
                <v-col
                  sm="4"
                  cols="12"
                  v-for="(item, i) in listMajors"
                  :key="i"
                >
                  <v-card
                    color="#0d234b"
                    max-width="auto"
                    height="auto"
                    dark
                    style="border-radius: 15px"
                    class="mx-auto"
                    @click="
                      $router.push({
                        name: 'TopManagerGen',
                        params: { id: item.id, name_en: item.name_en },
                      })
                    "
                  >
                    <v-list-item three-line>
                      <v-list-item-content>
                        <v-list-item-title class="text-h4 mb-1">
                          <v-avatar color="white" size="50px">
                            <span class="black--text text-h5">{{
                              item.annual_count
                            }}</span>
                          </v-avatar>
                        </v-list-item-title>
                        <v-list-item-title class="text-h6">{{
                          item.name_en
                        }}</v-list-item-title>
                      </v-list-item-content>

                      <v-list-item-avatar tile size="100px">
                        <i
                          class="fas fa-user-graduate fa-4x float-right"
                          style="color: white"
                        ></i>
                      </v-list-item-avatar>
                    </v-list-item>
                    <router-link
                      style="text-decoration: none"
                      :to="'top-managerr/gen/' + item.id + '&' + item.name_en"
                      tag="button"
                    >
                    </router-link>

                    <v-divider color="white"></v-divider>
                    <v-card-actions>
                      <span>View Transcript </span>
                      <v-icon size="15px" class="ml-2">
                        mdi-arrow-right-bold
                      </v-icon>
                    </v-card-actions>
                  </v-card>
                </v-col>
              </v-row>
            </v-col>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <div>
      <div class="loading">
        <loading
          :active.sync="isLoading"
          :is-full-page="fullPage"
          :opacity="0.9"
          background-color="#dedede"
          :width="40"
          :height="40"
        >
          <div v-if="myLoading">
            <img
              width="100%"
              src="https://firebasestorage.googleapis.com/v0/b/schoolbase.appspot.com/o/images%2Floading.gif?alt=media&token=58553b10-7335-42a6-a0c8-8a6d55927816"
            />
          </div>
        </loading>
      </div>
    </div>
  </v-app>
</template>

<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { countAnualScore } from "@schoolbase/web-client-lib";
import store from "@/store";

export default {
  props: ["fullPage", "alertMessage", "color", "alertText"],
  data: () => ({
    listMajors: [],
    token: store.getters.getToken,
    snackbar: {
      show: false,
      message: null,
      color: null,
    },
    isLoading: false,
    myLoading: false,
  }),
  computed: {},
  methods: {
    async loadMajor() {
      try {
        this.isLoading = true;
        this.myLoading = true;
        const APIResponse = await countAnualScore(this.token);
        this.listMajors = APIResponse.payload.top_manager;
        if (APIResponse.token) {
          this.$store.commit("LOGGED_TOKEN", APIResponse.token);
        }

        this.isLoading = false;
        this.myLoading = false;
      } catch (e) {
        this.isLoading = false;
        this.myLoading = false;

        // Logout user when unauthorized call
        if (e.response?.status == 401) {
          this.$store.dispatch("logOut");
          this.$router.go({
            name: "SignIn",
          });

          return;
        }
        this.snackbar = {
          message: e,
          color: "error",
          show: true,
        };
      }
    },
  },
  components: {
    Loading,
  },
  mounted() {
    this.loadMajor();
  },
};
</script>
<style scoped>
.v-subheader {
  color: rgb(0, 255, 42) !important;
}
@media (max-width: 576px) {
}
</style>
